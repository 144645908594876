import React from 'react';

const Header = () => {
  return (
    <header className="header">
      <div className="logo" href="\">
        <h1 className='header-credkar'><font color="#46CA61">Cred</font><font color="white">Kar</font></h1>
      </div>

      <nav className="navbar">
        <ul>
          <li><a href="#products">SME Products</a></li>
          <li><a href="#process">Industries</a></li>
          <li><a href="#faq">Resources</a></li>
          <li><a href="#about">About Us</a></li>
        </ul>
      </nav>
      <button className="signInBtn">Sign In</button>
    </header>
  );
};

export default Header;
