import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="footer">
      <h1 className='footer-credkar'><font color="#46CA61">Cred</font><font color="black">Kar</font></h1>

      <div className="footer-links">
        <p style={{color:"#646161"}}>Personal Loan&nbsp; &nbsp; &nbsp;One Card&nbsp; &nbsp; &nbsp;Savings&nbsp; &nbsp; &nbsp;Checking&nbsp; &nbsp; &nbsp;Contact&nbsp; &nbsp; &nbsp;Help&nbsp; &nbsp; &nbsp;Support  </p>
      </div>
      
      <div className="social-links">
        <div class="insta-link-div">
          <i class="bi bi-twitter icon-circle insta-link"></i>
        </div>
        <div class="insta-link-div">
          <i class="bi bi-facebook icon-circle insta-link"></i>
        </div>
        <div class="insta-link-div">
          <i class="bi bi-instagram icon-circle insta-link"></i>
        </div>
        <div class="insta-link-div">
          <i class="bi bi-linkedin icon-circle insta-link"></i>
        </div>
      </div>

      
      <p className='send-feedback'>Send Your Feedback :&nbsp;info@credkar</p>

      <div className="footer-links">
        <p>Privacy Policy   &nbsp;|   &nbsp;Terms & Condition   &nbsp;|   &nbsp;Cookie Notice   &nbsp;|   &nbsp;Copyright Policy   &nbsp;|   &nbsp;Data Policy    </p>
      </div>

      <p  className="footer-links">&copy; 2024 CredKar. All Rights Reserved.</p>

    </footer>
  );
};

export default Footer;
